<template>
  <b-card>
    <b-card-title>
      Score review ({{ score }})
    </b-card-title>
    <b-row>
      <b-col
        lg="3"
        md="3"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(item, key) in scoreResults"
            :key="key"
            class="d-flex justify-content-between"
          >
            <span><strong>{{ key.replaceAll("_", " ").toUpperCase() }}:</strong></span>  <span>{{ item }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col
        lg="3"
        md="3"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td>
              {{ user.firstName }}  {{ user.lastName }}
            </td>
          </tr><tr>
            <th>
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td>
              {{ user.email }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ user.address }}, {{ user.zipcode }} {{ user.city }} {{ user.state }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </th>
            <td>
              {{ user.phone }}
            </td>
          </tr>
          <tr />
          <tr>
            <th>
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td>
              {{ user.country.name }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="UserPlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered</span>
            </th>
            <td>
              {{ formatDateTime(user.createdAt) }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CloudIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registration IP</span>
            </th>
            <td>
              {{ user.ip }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Verified at</span>
            </th>
            <td>
              {{ formatDateTime(user.kyc_at) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>

import {
  BCard, BRow, BCol, BCardTitle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BListGroup,
    BListGroupItem,
  },
  props: {
    user: Object,
    scoreResults: Object,
    score: Number,
  },
  methods: {
    formatDateTime,
  },
}

</script>
