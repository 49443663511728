<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="5"
          class="mb-2"
        >
          <b-form-group>
            <h5>Account id</h5>
            <b-form-input
              v-model="accountId"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          lg="2"
          class="mb-2"
        >
          <b-button
            :disabled="loading"
            @click.prevent="search"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="loading">
      <score-preview
        v-if="result"
        :user="result.user"
        :score="result.score"
        :score-results="result.scoreResults"
      />

      <trades-view
        v-if="result && result.hedging.length > 0"
        :user="result.user"
        :trades="result.hedging"
        :highlighted="highlighted"
        title="Hedging"
        @show-linkage="showLinkage"
      />

      <trades-view
        v-if="result && result.copy.length > 0"
        :user="result.user"
        :trades="result.copy"
        :highlighted="highlighted"
        title="Copy"
        @show-linkage="showLinkage"
      />

      <b-card v-if="result">
        <b-card-title>User IPs</b-card-title>
        <b-table
          :items="result.user_ips"
          :fields="ipFields"
          striped
          responsive
          small
          :sort-by="'country'"
          :sort-desc="true"
        >
          <template #cell(vpn)="data">
            <b-badge
              v-if="data.item.is_vpn === null"
              variant="light-info"
            >
              UNKNOWN
            </b-badge>
            <b-badge
              v-else-if="data.item.is_vpn === true"
              variant="light-danger"
            >
              YES
            </b-badge>
            <b-badge
              v-else
              variant="light-success"
            >
              NO
            </b-badge>
          </template>

          <template #cell(proxy)="data">
            <b-badge
              v-if="data.item.is_proxy === null"
              variant="light-info"
            >
              UNKNOWN
            </b-badge>
            <b-badge
              v-else-if="data.item.is_proxy === true"
              variant="light-danger"
            >
              YES
            </b-badge>
            <b-badge
              v-else
              variant="light-success"
            >
              NO
            </b-badge>
          </template>

          <template #cell(tor)="data">
            <b-badge
              v-if="data.item.is_tor === null"
              variant="light-info"
            >
              UNKNOWN
            </b-badge>
            <b-badge
              v-else-if="data.item.is_tor === true"
              variant="light-danger"
            >
              YES
            </b-badge>
            <b-badge
              v-else
              variant="light-success"
            >
              NO
            </b-badge>
          </template>

          <template #cell(hosting)="data">
            <b-badge
              v-if="data.item.is_hosting === null"
              variant="light-info"
            >
              UNKNOWN
            </b-badge>
            <b-badge
              v-else-if="data.item.is_hosting === true"
              variant="light-danger"
            >
              YES
            </b-badge>
            <b-badge
              v-else
              variant="light-success"
            >
              NO
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="linkageModal"
      title="Users connections on another accounts"
      :no-close-on-backdrop="true"
      size="xl"
      hide-footer
    >
      <account-linkage-table-gql :columns="linkageColumns" />
    </b-modal>
  </div>
</template>
<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BCardTitle, BTable, BBadge, BModal,
} from 'bootstrap-vue'
import ToolsAPI from '@/api/tools'
import { formatDateTime } from '@/plugins/formaters'
import ScorePreview from '@/views/tools/risk-score/components/ScorePreview.vue'
import TradesView from '@/views/tools/risk-score/components/TradesView.vue'
import AccountLinkageTableGql from '@/components/entity/accountLinkage/AccountLinkageTableGql.vue'

const ipFields = [
  {
    key: 'ip',
    sortable: true,
  },
  {
    key: 'country',
    sortable: true,
  },
  {
    key: 'city',
    sortable: true,
  },
  {
    key: 'vpn',
    sortable: true,
  },
  {
    key: 'proxy',
    sortable: true,
  },
  {
    key: 'tor',
    sortable: true,
  },
  {
    key: 'hosting',
    sortable: true,
  },
]

export default {
  components: {
    AccountLinkageTableGql,
    BModal,
    TradesView,
    ScorePreview,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BTable,
    BBadge,
  },
  data() {
    return {
      accountId: null,
      loading: false,
      result: null,
      highlighted: null,
      ipFields,
      searchId: null,
    }
  },
  computed: {
    accountLogin() {
      return this.$route.params.id
    },
    linkageColumns() {
      return [
        {
          name: 'customQuickSearch', label: 'Quick search', filterable: 'like', visible: false,
        },
        {
          name: 'searchUser',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.searchId,
          visible: true,
        },
        {
          name: 'account', label: 'Account',
        },
        {
          name: 'targetUser',
          label: 'Target user',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          visible: true,
        },
        {
          name: 'linked_account', label: 'Linked account',
        },
        {
          name: 'createdAt', label: 'Created at',
        },
      ]
    },
  },
  beforeMount() {
    if (this.accountLogin) {
      this.accountId = this.accountLogin
      this.search()
    }
  },
  methods: {
    formatDateTime,
    search() {
      this.loading = true
      ToolsAPI.getScoreAnalysis(this.accountId)
        .then(response => {
          this.result = response
          const hedgeEmails = this.result.hedging.map(hedge => hedge.found.map(group => group.trades[0].email)).flat()
          const copyEmails = this.result.copy.map(copy => copy.found.map(group => group.trades[0].email)).flat()
          const emailsList = hedgeEmails.concat(copyEmails)
          const emails = {}
          const emailsSet = new Set(emailsList)
          emailsSet.forEach(email => {
            emails[email] = 0
          })
          emailsSet.forEach(email => {
            emailsList.forEach(e => {
              if (e === email) {
                emails[e] += 1
              }
            })
          })

          this.highlighted = Object.keys(emails).filter(key => emails[key] > 1)
        }).finally(() => {
          this.loading = false
        })
    },
    showLinkage(userId) {
      this.searchId = userId

      this.$bvModal.show('linkageModal')
    },
  },
}
</script>
