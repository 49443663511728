<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ title }}
          </h4>
        </b-col>
        <b-col>
          <b-button
            variant="outline-primary"
            class="btn-icon float-right mb-auto mr-2"
            @click="hideSensitiveInformation = !hideSensitiveInformation"
          >
            <feather-icon :icon="!hideSensitiveInformation ? 'EyeOffIcon' : 'EyeIcon'" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-for="(trade, key) in trades"
      :key="key"
    >
      <trade-table
        :name="user.email"
        :accounts="trade.group.trades"
        :fields="fields"
        @show-linkage="value => $emit('show-linkage', value)"
      >
        <template #cell(open_datetime)="data">
          {{ formatDateTime(data.item.open_datetime) }}
        </template>

        <template #cell(close_datetime)="data">
          {{ formatDateTime(data.item.close_datetime) }}
        </template>

        <template #cell(type)="data">
          <span v-if="data.item.type === 0">BUY</span>
          <span v-else-if="data.item.type === 1">SELL</span>
        </template>

        <template #cell(open_price)="data">
          {{ data.item.open_price }}
        </template>

        <template #cell(close_price)="data">
          {{ data.item.close_price }}
        </template>

        <template #cell(profit)="data">
          {{ data.item.profit }}
        </template>

        <template #cell(email)="data">
          <b-link :to="{ name: 'user-detail', params: { id: data.item.user_id } }">
            {{ data.item.email }}
          </b-link>
        </template>

        <template #cell(login)="data">
          <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
            {{ data.item.account_id }}
          </b-link>
        </template>

        <template #cell(account_type_name)="data">
          {{ data.item.account_type_name }}
        </template>

        <template #cell(account_subtype_name)="data">
          {{ data.item.account_subtype_name }}
        </template>

        <template #cell(country)="data">
          {{ data.item.country }} <span v-if="data.item.kyc_country">(KYC: {{ data.item.kyc_country }})</span>
        </template>
      </trade-table>

      <hr class="my-1">

      <!--      matching trades -->
      <trade-table
        v-for="(account, key) in getSorted(trade.found)"
        :key="key"
        :name="hideSensitiveInformation ? `${account.trades[0].email.split('@')[0].slice(0,2)}***@${account.trades[0].email.split('@')[1]}` : account.trades[0].email"
        :highlighted="highlighted.includes(account.trades[0].email)"
        :accounts="account.trades"
        :fields="fields"
        @show-linkage="value => $emit('show-linkage', value)"
      >
        <template #cell(open_datetime)="data">
          {{ formatDateTime(data.item.open_datetime) }}
        </template>

        <template #cell(close_datetime)="data">
          {{ formatDateTime(data.item.close_datetime) }}
        </template>

        <template #cell(type)="data">
          <span v-if="data.item.type === 0">BUY</span>
          <span v-else-if="data.item.type === 1">SELL</span>
        </template>

        <template #cell(open_price)="data">
          {{ data.item.open_price }}
        </template>

        <template #cell(close_price)="data">
          {{ data.item.close_price }}
        </template>

        <template #cell(profit)="data">
          {{ data.item.profit }}
        </template>

        <template #cell(email)="data">
          <b-link :to="{ name: 'user-detail', params: { id: data.item.user_id } }">
            {{ hideSensitiveInformation ? `${data.item.email.split('@')[0].slice(0,2)}***@${data.item.email.split('@')[1]}` : data.item.email }}
          </b-link>
        </template>

        <template #cell(login)="data">
          <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
            {{ hideSensitiveInformation ? `${data.item.account_id.toString().slice(0,3)}********` : data.item.account_id }}
          </b-link>
        </template>

        <template #cell(account_type_name)="data">
          {{ data.item.account_type_name }}
        </template>

        <template #cell(account_subtype_name)="data">
          {{ data.item.account_subtype_name }}
        </template>

        <template #cell(country)="data">
          {{ data.item.country }} <span v-if="data.item.kyc_country">(KYC: {{ data.item.kyc_country }})</span>
        </template>
      </trade-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'
import TradeTable from '@/views/trading-history/components/TradeTable.vue'
import { formatDateTime } from '@/plugins/formaters'

const fields = [
  {
    key: 'login',
    sortable: true,
  },
  {
    key: 'email',
    sortable: true,
  },
  {
    key: 'symbol',
    sortable: false,
  },
  {
    key: 'open_datetime',
    sortable: true,
  },
  {
    key: 'close_datetime',
    sortable: true,
  },
  {
    key: 'type',
    sortable: true,
  },
  {
    key: 'open_price',
    sortable: true,
  }, {
    key: 'close_price',
    sortable: true,
  },
  {
    key: 'volume',
    sortable: true,
  }, {
    key: 'profit',
    sortable: true,
  },
  {
    key: 'account_type_name',
    sortable: true,
  },
  {
    key: 'account_subtype_name',
    sortable: true,
  },
  {
    key: 'country',
    sortable: true,
  },
]

export default {
  components: {
    TradeTable,
    BCard,
    BLink,
    BRow,
    BCol,
    BButton,
  },
  props: {
    title: String,
    trades: Array,
    user: Object,
    highlighted: Array,
  },
  data() {
    return {
      fields,
      hideSensitiveInformation: false,
    }
  },
  methods: {
    formatDateTime,
    getSorted(groups) {
      const identicalCountry = groups.filter(item => item.trades[0].country === this.user.country.name || item.trades[0].kyc_country === this.user.country.name)
      const rest = groups.filter(item => item.trades[0].country !== this.user.country.name && item.trades[0].kyc_country !== this.user.country.name)

      return identicalCountry.concat(rest)
    },
  },
}
</script>
