<template>
  <div>
    <h3>
      <b-badge
        v-if="highlighted"
        variant="light-danger"
      >
        {{ name }}
      </b-badge>
      <span v-else>{{ name }} </span>
      <b-button
        v-if="showLinkageBtn"
        variant="outline-primary"
        @click="$emit('show-linkage',accounts[0].user_id)"
      >
        <feather-icon icon="SearchIcon" />
      </b-button>
    </h3>
    <b-table
      :items="accounts"
      :fields="fields"
      striped
      responsive
      small
      :sort-by="'email'"
      :sort-desc="true"
    >
      <template
        v-for="(_, slotName) of $scopedSlots"
        v-slot:[slotName]="scope"
      >
        <slot
          :name="slotName"
          v-bind="scope"
        />
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BBadge,
    BButton,
  },
  props: {
    accounts: Array,
    name: String,
    slots: Array,
    fields: Array,
    highlighted: {
      type: Boolean,
      default: false,
    },
    showLinkageBtn: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
